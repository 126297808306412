import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { VIEW_HORIZONTAL } from "@pages/current-trades/current-trades-filters/utils";
import { CarChecks } from "@components/car-checks/car-checks";
import { formatValueWithSpaces } from "@utils/format";
import LogoSeller from "@components/utils/LogoSeller/LogoSeller";
import clsx from "clsx";
import { citiesStore } from "@mobx/cities";

export const CarItem = ({ sale, sellerable, view, params, lot, relatedStatusReport }) => {
	const [currentStatusReport, setCurrentStatusReport] = useState();
	const [city, setCity] = useState();
	const [photo, setPhoto] = useState();
	const { cities } = citiesStore;
	const [carLink, setCarLink] = useState("");

	const carName = (
		<Link to={carLink} className="car-name">
			{sale?.display_name}
		</Link>
	);

	useEffect(() => {
		let report = relatedStatusReport.find(x => x.sale_id === sale.id);
		setCurrentStatusReport(report);
		if (!citiesStore.cities.length) {
			citiesStore.fetch();
		}
		setCity(cities.find(x => x.id === report.status_report?.city_id));
		setPhoto(report.status_report?.photos[0]);
		setCarLink(`/car/${sale?.id}${params ? `?${params}` : ""}&statusReportId=${report.status_report?.id}`);
	}, []);

	useEffect(() => {

	}, []);

	return (
		<div className="car-wrapper">
			<Link to={carLink} className="car-card__main-link">
				<img
					src={photo?.thumbnails_urls?.small}
					className="car-card__main-image"
					alt=""
				/>
				{view !== VIEW_HORIZONTAL && sellerable?.logo?.url && <div className="car-card__main-icon">
					<LogoSeller image={sellerable.logo.url} background small />
				</div>}
			</Link>
			<div>
				{view === VIEW_HORIZONTAL ? (
					<>
						<div className="d-flex align-items-center justify-content-between mt-12px">
							{carName}
							<CarChecks
								statusReportFile={sale?.status_report_file}
								inspectionType={sale?.inspection_type}
								vin={sale?.car?.vin}
								lot={lot}
								isLotSale={true}
							/>
						</div>
						<div className="car-card__header-line" />
					</>
				) : carName}
				<Link
					className={clsx("car-card__info-block", view === VIEW_HORIZONTAL && "car-card__info-block-padding")}
					to={carLink}>
					<div className="d-flex align-items-center car-card__info-block-a">
						<div className="year icon"><img src="/img/icons/date-icon.svg" alt="" /></div>
						<div className="value">{sale?.car?.year} г</div>
					</div>
					<div className="d-flex align-items-center car-card__info-block-b">
						<div className="city icon"><img src="/img/icons/map-marker-icon.svg" alt="" /></div>
						<div className="value">{city?.name}</div>
					</div>
					<div className="d-flex align-items-center car-card__info-block-c">
						<div className="mileage icon"><img src="/img/icons/speed-icon.svg" alt="" /></div>
						<div
							className="value">{currentStatusReport?.status_report?.mileage ? formatValueWithSpaces(currentStatusReport?.status_report?.mileage) : "-"} км
						</div>
					</div>
					<div className="d-flex align-items-center car-card__info-block-d">
						<div className="engine icon"><img src="/img/icons/engine-icon.svg" alt="" /></div>
						<div className="value">{sale?.car?.engine_volume} см3</div>
					</div>
					<div className="d-flex align-items-center car-card__info-block-e">
						<div className="reg_number icon"><img src="/img/icons/gearbox.svg" alt="" /></div>
						<div className="value">{sale?.car?.transmission?.name}</div>
					</div>
					<div className="d-flex align-items-center car-card__info-block-f">
						<div className="engine icon"><img src="/img/icons/power-icon.svg" alt="" /></div>
						<div className="value">{sale?.car?.power} л.с.</div>
					</div>
					{view === VIEW_HORIZONTAL && sellerable?.logo?.url && <div className="car-card__logoSeller">
						<LogoSeller image={sellerable.logo.url} />
					</div>}
				</Link>
				<div className="d-flex mt-2">
					<div className="vin-title">VIN:</div>
					<div className="vin">
						<span>{sale?.car?.vin}</span>
					</div>
				</div>
			</div>
		</div>
	);
};
