import { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";

import { Button } from "@components/button/button";
import { Tooltip } from "@components/tooltip/tooltip";
import QuestionTooltip from "@components/tooltip/tooltip-question";
import { auctionsStore } from "@mobx/auctions";
import { VIEW_HORIZONTAL } from "@pages/current-trades/current-trades-filters/utils";
import { getCurrencySymbol } from "@utils/common";
import { formatValueWithSpaces } from "@utils/format";

import { LotCars } from "./lot-cars/lot-cars";

import { Modal } from "react-bootstrap";

import clsx from "clsx";
import "./auction-down.scss";

export const LotCardDownAuction = (props) => {
	const {
		lot,
		auctionId,
		sellerable,
		lotTimer,
		view,
		classes,
		isWinner,
		isLost,
		hideCars,
		isMobile,
		elFavouriteBtn,
		relatedStatusReport
	} = props;
	const clockRef = useRef();

	const [showModal, setShowModal] = useState(false);

	const onToggleModal = () => {
		setShowModal((prev) => !prev);
	};

	useEffect(() => {
		if (lotTimer) {
			clockRef?.current?.start?.();
		}
	}, [lotTimer]);

	const onHandleBetAuction = () => {
		if (lot.is_active)
			auctionsStore.betAuction(auctionId, lot.id, lot.price);
		onToggleModal();
	};

	const modal = () => {
		return (
			<Modal
				show={showModal}
				onHide={onToggleModal}
				className={clsx(
					"d-flex align-items-center justify-content-center modal-size-small"
				)}
			>
				<div className="head-text text-center mb-3">
					{lot?.sales[0]?.display_name}
				</div>
				<div className={"d-flex justify-content-center gap-2 mb-3"}>
					<b>Цена:</b>
					<b>{lot?.price} ₽</b>
				</div>
				<Button
					onClick={onHandleBetAuction}
					preset="primary"
					className={"mb-3"}
				>
					Купить
				</Button>
				<Button preset="secondary" onClick={onToggleModal}>
					Закрыть
				</Button>
			</Modal>
		);
	};

	const elBetButton = () => {
		let text = "Купить";

		if (isWinner) {
			text = "Лот куплен";
		} else if (isLost) {
			text = "Торги закончились";
		}

		return (
			<Button
				onClick={onToggleModal}
				className="auction__bet-btn"
				disabled={!lot.is_active}
			>
				{text}
			</Button>
		);
	};

	const elPrice = () => {
		return (
			<div className="d-flex align-items-center">
				<Tooltip text="Текущая стоимость" isDisabled={isMobile}>
					<div className="d-flex align-items-center last-bet-wrapper">
						<img
							src="/img/icons/arrow-up-bordered-icon.svg"
							alt=""
							className="auction-down__price-icon"
						/>
						<div className="price">
							{formatValueWithSpaces(lot.price)}{" "}
							{getCurrencySymbol()}
						</div>
					</div>
				</Tooltip>
				{view === VIEW_HORIZONTAL ? (
					<div className="ml-5px">
						{lot.is_vat_included ? "С НДС" : "Без НДС"}
					</div>
				) : (
					<QuestionTooltip
						text={
							lot.is_vat_included ? "Цена с НДС" : "Цена без НДС"
						}
					/>
				)}
			</div>
		);
	};

	const elMinimalPrice = () => {
		return (
			<div className="d-flex align-items-center">
				<Tooltip text="Минимальная стоимость" isDisabled={isMobile}>
					<div className="d-flex align-items-center last-bet-wrapper">
						<img
							src="/img/icons/auction-price.svg"
							alt=""
							className="auction-down__price-icon"
						/>
						<div className="price price_light opacity-50">
							{formatValueWithSpaces(lot.minimal_price)}{" "}
							{getCurrencySymbol()}
						</div>
					</div>
				</Tooltip>
				{view !== VIEW_HORIZONTAL && (
					<QuestionTooltip text={"Минимальная стоимость"} />
				)}
			</div>
		);
	};

	const elBet = () => {
		return (
			<Tooltip text="Шаг понижения" isDisabled={isMobile}>
				<div className="d-flex align-items-center auction-down__decrease-step">
					<img src="/img/icons/bid-down.svg" alt="" />
					<div className="minimal-price">
						{formatValueWithSpaces(lot.minimal_step)}{" "}
						{getCurrencySymbol()}
					</div>
				</div>
			</Tooltip>
		);
	};

	const elTop = () => {
		if (lotTimer) {
			return (
				<>
					<div className="disabled-auctions" />
					<div className="auction-down__prestart-timer">
						<img src="/img/icons/interval-icon.svg" alt="" />
						<Countdown ref={clockRef} date={lotTimer} />
					</div>
				</>
			);
		}

		if (lot.finished_at || !lot.is_active) {
			return <div className="disabled-auctions" />;
		}
	};

	const elActions = () => {
		if (!isMobile && view === VIEW_HORIZONTAL) {
			return (
				<div className="actions">
					<div className="w-100 position-relative d-flex justify-content-between align-items-center">
						{elPrice()}
						{elMinimalPrice()}
						{elBet()}
						{elBetButton()}
						{elFavouriteBtn}
					</div>
				</div>
			);
		}

		if (isMobile && hideCars) {
			return (
				<div className="actions actions-mobile">
					<div className="d-flex justify-content-between align-items-center">
						{elPrice()}
						{elBet()}
					</div>
					<div className="d-flex justify-content-between align-items-center">
						{elMinimalPrice()}
						{elFavouriteBtn}
					</div>
					{elBetButton()}
				</div>
			);
		}

		return (
			<div className="actions">
				{!isMobile && lot.sales.length > 1 ? (
					<div className="w-100 position-relative d-flex justify-content-between align-items-center">
						{elPrice()}
						{elBet()}
						{elBetButton()}
						{elFavouriteBtn}
					</div>
				) : (
					<div className="w-100 position-relative d-flex flex-column">
						{elBetButton()}
						<div className="d-flex justify-content-between align-items-center mt-15px">
							{elPrice()}
							{elBet()}
						</div>
						<div className="d-flex justify-content-between align-items-center mt-15px">
							{elMinimalPrice()}
							{elFavouriteBtn}
						</div>
					</div>
				)}
			</div>
		);
	};

	return (
		<div
			className={`lot-car-wrapper position-relative ${classes.join(" ")}`}
		>
			{elTop()}
			{!hideCars && (
				<LotCars lot={lot} view={view} auctionId={auctionId} sellerable={sellerable}
						 relatedStatusReport={relatedStatusReport} />
			)}
			{elActions()}
			{modal()}
		</div>
	);
};
