import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"

import { CarsModal } from "../../../cars-modal/cars-modal"

import ReportList from "@pages/auction-form/ReportList/ReportList"
import ReportModal from "@pages/auction-form/ReportModal/ReportModal"
import ReportSelected from "@pages/auction-form/ReportSelected/ReportSelected"
import moment from "moment/moment"
import "./lots.scss"

export const LotSalesTable = observer(
	({
		lot,
		sales,
		onChangeSales,
		onChangeReports,
		withCreateSale,
		singleSelect,
		isSavedLot,
		isEdit,
		isNewLot,
		isUpAuction
	}) => {
		const [show, setShow] = useState(!sales?.length)
		const [showReport, setShowReport] = useState(false)
		const [reports, setReports] = useState([])
		const [selectedReports, setSelectedReports] = useState([])

		const onRemove = (saleId) => {
			onChangeSales(sales.filter((item) => item.id !== saleId))
		}

		const onShowReports = (reports) => {
			setReports(reports)
			setShowReport(true)
		}

		const onSelectReport = (obj) => {
			setShowReport(false)
			let isId = selectedReports.find(x => x.status_report_id === obj.status_report_id)
			if (!isId) setSelectedReports(prev => [...prev, obj])
		}

		const onRemoveStatusReport = (id) => {
			setSelectedReports(arr => arr.filter(x => x.status_report_id !== id))
		}

		useEffect(() => {
			if (onChangeReports) onChangeReports(selectedReports)
		}, [selectedReports])

		useEffect(() => {
			if (isEdit) {
				setSelectedReports(lot?.relatedStatusReport.map(x =>
					Object.assign({}, {
						sale_id: x.sale_id,
						status_report_id: x.status_report.id,
						closed_report: x.status_report?.closed_by?.display_name,
						closed_at: moment(x.status_report?.closed_at).format("DD.MM.YYYY HH:mm")
					}))
				)
			}
		}, [lot])

		useEffect(() => {
			if (!isEdit && sales.length) {
				sales.forEach(x => {
					if (x.status_reports.length === 1) {
						let report = x.status_reports[0]
						setSelectedReports(prev => [...prev, Object.assign({}, {
							sale_id: report.sale_id,
							status_report_id: report.id,
							closed_report: report.closed_by?.display_name,
							closed_at: moment(report?.closed_at).format("DD.MM.YYYY HH:mm")
						})])
					}
				})
			}
		}, [sales])

		const elTable = () => {
			return (
				<div className="lot__table">
					{sales?.map((sale) => {

						let report = selectedReports.find(x => x.sale_id === sale.id)

						return (
							<div key={`car-sale-${sale.id}`} className="lot__table-item-table">
								<div
									className="lot__table-item-row"
								>
									<div className="lot__table-item-tr">
										<a
											href={`/car/${sale.id}`}
											className="lot__table-item-tr-car-link"
											target="_blank"
											rel="noreferrer"
										>
											{sale.car?.brand?.name}
										</a>
									</div>
									<div className="lot__table-item-tr">
										{sale.car?.vin}
									</div>
									<div className="lot__table-item-tr">
										{sale.city?.name}
									</div>
									{withCreateSale && isNewLot && (
										<img
											src="/img/icons/delete-icon.svg"
											alt=""
											onClick={() => onRemove(sale.id)}
											className="cursor-pointer"
										/>
									)}
								</div>
								{!isSavedLot &&
									<div className="report-info">
										{report ? (
											<ReportSelected report={report} onRemove={onRemoveStatusReport} />
										)
											:
											<button
												className={"add-report"}
												onClick={() => onShowReports(sale.status_reports)}>
												<span>Прикрепить отчет</span> *
											</button>}
									</div>
								}
							</div>
						)
					})}
				</div>
			)
		}

		return (
			<>
				<div className="d-flex align-items-center justify-content-between">
					<div className="d-flex align-items-center justify-content-between w-100">
						{withCreateSale && !sales?.length && (
							<>
								<button
									onClick={() => setShow(true)}
									className="btn btn-primary"
									type="button"
								>
									<img
										src="/img/icons/plus-icon.svg"
										alt=""
									/>
									Добавить автомобиль
								</button>
								<div className="text-info">
									<img
										src="/img/icons/info-icon.svg"
										alt=""
									/>
									Для создания лота добавьте минимум один
									автомобиль
								</div>
							</>
						)}
					</div>
					{withCreateSale && show && (
						<CarsModal
							show={true}
							onHide={() => setShow(false)}
							onChangeSales={onChangeSales}
							sales={sales}
							singleSelect={singleSelect}
							isUpAuction={isUpAuction}
							isNewLot={isNewLot}
							isEdit={isEdit}
						/>
					)}
				</div>
				{sales?.length > 0 && elTable()}
				{sales?.length > 0 && !isSavedLot && !isEdit && (
					<button
						onClick={() => setShow(true)}
						className="btn-add-car mt-12px"
						type="button"
					>
						<img src="/img/icons/icon-left-blue.svg" alt="" />
						Добавить автомобиль
					</button>
				)}
				<ReportModal show={showReport} onHide={setShowReport}>
					<ReportList items={reports} onSelect={onSelectReport} />
				</ReportModal>
			</>
		)
	}
)
